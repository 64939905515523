<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Code</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Code"
              v-model="filter.code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Category</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Category"
              v-model="filter.name"
            ></b-form-input>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click="getCategory(true)">
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom card-stretch-fourth p-5 gutter-b">
      <div class="d-flex my-3 justify-content-start">
        <button
          class="btn btn-success btn-md"
          @click="showInputModal(null, 'Form Add Category')"
        >
          <i class="flaticon-add"></i> Add Category
        </button>
      </div>

      <div class="card-body p-0">
        <complete-table :loading="loading" :data="filteredCategories">
          <template #header>
            <tr class="text-left">
              <th class="pl-7">Code</th>
              <th class="pl-7">Category</th>
              <th class="pl-7 text-center">Action</th>
            </tr>
          </template>

          <template #defaultBody="{item: category, i}">
            <!-- <template v-for="(category, i) in filteredCategories"> -->
              <tr v-bind:key="i" class="border-0">
                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ category.code }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ category.name }}
                  </span>
                </td>

                <td class="pl-0 pt-8 text-center">
                  <a
                    class="btn btn-light-success font-weight-bolder font-size-sm"
                    @click="
                      showInputModal(category, 'Form Update Category', false)
                    "
                  >
                    <i class="flaticon-edit"></i>
                  </a>

                  <a
                    class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                    @click="showDeleteModal(category)"
                  >
                    <i class="flaticon-delete"></i>
                  </a>
                </td>
              </tr>
            <!-- </template> -->
          </template>
        </complete-table>
      </div>
    </div>

    <b-modal ref="input" hide-footer :title="modalTitle">
      <div class="d-block text-center">
        <form>
          <div class="row">
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Code *</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Code"
                v-model="form.code"
                :disabled="!isCreate"
              ></b-form-input>
            </div>
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Category *</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Category Name"
                v-model="form.name"
              ></b-form-input>
            </div>
          </div>
        </form>

        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            block
            @click="isCreate ? sav() : updat()"
          >
            Save
          </b-button>

          <b-button
            :disabled="loading"
            class="btn btn-secondary mt-0"
            block
            @click="closeInputModal"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal ref="delete" hide-footer title="Warning">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large"
              >Are You Sure Want To Delete Design Category</label
            >
          </div>
        </div>
        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            @click="delet"
            block
          >
            Delete
          </b-button>

          <b-button
            class="btn btn-secondary mt-0"
            block
            @click="closeDeleteModal"
            :disabled="loading"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import {
  DELETE_CATEGORY,
  GET_CATEGORY,
  SAVE_CATEGORY,
  UPDATE_CATEGORY,
} from "../../../core/services/store/category.module";

export default {
  components: { CompleteTable },

  data() {
    return {
      filter: {
        code: null,
        name: null,
      },
      currentItem: null,
      modalTitle: null,
      form: {
        code: null,
        name: null,
      },
      isCreate: true,
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.category.loading,
      categories: (state) => state.category.categories?.data,
    }),

    filteredCategories() {
      // return this.categories?.filter((category) => {
      //   return (this.filter.code ? category.code?.toUpperCase()?.includes(this.filter.code?.toUpperCase()) : true)
      //       && (this.filter.name?.toUpperCase() ? category.name?.toUpperCase()?.includes(this.filter.name?.toUpperCase()) : true);
      // }) 
      return this.categories;
    },
  },

  methods: {
    showInputModal(category, modalTitle, isCreate = true) {
      this.currentItem = category;

      this.form = {
        code: category?.code,
        name: category?.name,
      };

      this.modalTitle = modalTitle;

      this.isCreate = isCreate;

      this.$refs["input"].show();
    },

    showDeleteModal(category) {
      this.currentItem = category;

      this.$refs["delete"].show();
    },

    closeInputModal() {
      this.$refs["input"].hide();
    },

    closeDeleteModal() {
      this.$refs["delete"].hide();
    },

    getCategory(filter = false) {
      if (!arrayMoreThanOne(this.categories) || filter) {
        this.$store.dispatch(GET_CATEGORY, {
          params: this.filter
        });
      }
    },

    delet() {
      this.$store
        .dispatch(DELETE_CATEGORY, {
          code: this.currentItem?.code,
        })
        .then(() => {
          this.closeDeleteModal();
        });
    },

    sav() {
      this.$store.dispatch(SAVE_CATEGORY, this.form).then(() => {
        this.closeInputModal();
      });
    },

    updat() {
      this.$store.dispatch(UPDATE_CATEGORY, this.form).then(() => {
        this.closeInputModal();
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Design Category" }]);

    this.getCategory();
  },
};
</script>
